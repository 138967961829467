export const TECHNOLOGIES = [
    {"value":"1","label":"Ajax"},
    {"value":"2","label":" Amazon Web Services"},
    {"value":"3","label":" Angular Material"},
    {"value":"4","label":" Angular2"},
    {"value":"5","label":" Angular4"},
    {"value":"6","label":" AngularJS"},
    {"value":"7","label":" Apache Tapestry"},
    {"value":"8","label":" ASP.Net"},
    {"value":"9","label":" Aurelia"},
    {"value":"10","label":" Axure RP"},
    {"value":"11","label":" BackboneJS"},
    {"value":"12","label":" Bootstrap"},
    {"value":"13","label":" CakePHP"},
    {"value":"14","label":" CherryPy"},
    {"value":"15","label":" Codeigniter"},
    {"value":"16","label":" CoffeeScript"},
    {"value":"17","label":" CPanel"},
    {"value":"18","label":" CSS"},
    {"value":"20","label":" D3JS"},
    {"value":"21","label":" DC.js"},
    {"value":"22","label":" Django"},
    {"value":"23","label":" Drupal"},
    {"value":"24","label":" Electron"},
    {"value":"25","label":" EmberJS"},
    {"value":"26","label":" ExpressJS"},
    {"value":"27","label":" ExtJS"},
    {"value":"28","label":" ES6"},
    {"value":"29","label":" Firebase"},
    {"value":"30","label":" Flask"},
    {"value":"31","label":" Adobe Flex"},
    {"value":"32","label":" Flexbox"},
    {"value":"33","label":" Foundation"},
    {"value":"34","label":" Framework7"},
    {"value":"35","label":" FuelPHP"},
    {"value":"36","label":" Google Maps"},
    {"value":"37","label":" Grav"},
    {"value":"38","label":" Grunt"},
    {"value":"39","label":" Gulp"},
    {"value":"40","label":" GWT"},
    {"value":"41","label":" GWT Google Charts"},
    {"value":"42","label":" GWT High Charts"},
    {"value":"43","label":" Highcharts"},
    {"value":"44","label":" HTML"},
    {"value":"45","label":" HTML5"},
    {"value":"46","label":" HTTP"},
    {"value":"47","label":" JasmineJS"},
    {"value":"49","label":" Joomla"},
    {"value":"50","label":" jQuery"},
    {"value":"51","label":" jQueryUI"},
    {"value":"52","label":" JSF"},
    {"value":"53","label":" KnockoutJS"},
    {"value":"54","label":" KoaJS"},
    {"value":"55","label":" Laravel"},
    {"value":"56","label":" LESS"},
    {"value":"57","label":" LeafletJS"},
    {"value":"58","label":" MathML"},
    {"value":"59","label":" Magento Framework"},
    {"value":"60","label":" Material Design Lite"},
    {"value":"61","label":" Materialize"},
    {"value":"62","label":" Mootools"},
    {"value":"63","label":" MVC Framework"},
    {"value":"64","label":" PhantomJS"},
    {"value":"65","label":" Phalcon"},
    {"value":"66","label":" Prototype"},
    {"value":"67","label":" Polymer"},
    {"value":"68","label":" Pure.CSS"},
    {"value":"69","label":" RichFaces"},
    {"value":"70","label":" ReactJS"},
    {"value":"71","label":" RequireJS"},
    {"value":"72","label":" RESTful Web Services"},
    {"value":"73","label":" Ruby on Rails-2.1"},
    {"value":"74","label":" Ruby on Rails"},
    {"value":"75","label":" SASS"},
    {"value":"76","label":" Sencha Touch"},
    {"value":"77","label":" script.aculo.us"},
    {"value":"78","label":" SVG"},
    {"value":"79","label":" Symfony"},
    {"value":"80","label":" TurboGears"},
    {"value":"81","label":" Typescript"},
    {"value":"82","label":" VBScript"},
    {"value":"83","label":" VueJS"},
    {"value":"84","label":" W3CSS"},
    {"value":"86","label":" Web Icons"},
    {"value":"87","label":" Web2Py"},
    {"value":"88","label":" WebGL"},
    {"value":"89","label":" WebRTC"},
    {"value":"90","label":" Web Services"},
    {"value":"91","label":" Website Development"},
    {"value":"92","label":" Web Sockets"},
    {"value":"93","label":" Wordpress"},
    {"value":"94","label":" XHTML"},
    {"value":"95","label":" Yii"},
    {"value":"96","label":" Zend Framework"},
    {"value":"193","label":" Java"},
    {"value":"97","label":"JavaScript"},
    {"value":"98","label":" jQuery"},
    {"value":"99","label":" jQueryUI"},
    {"value":"100","label":" Jython"},
    {"value":"101","label":" Lua"},
    {"value":"102","label":" Numpy"},
    {"value":"103","label":" Perl"},
    {"value":"104","label":" PHP"},
    {"value":"105","label":" PHP-7"},
    {"value":"106","label":" PyGTK"},
    {"value":"107","label":" PyQt"},
    {"value":"108","label":" Python"},
    {"value":"109","label":" Python-3"},
    {"value":"110","label":" Python Data Structure"},
    {"value":"111","label":" Python Forensics"},
    {"value":"112","label":" Python Panda"},
    {"value":"113","label":" PySpark"},
    {"value":"114","label":" RSpec"},
    {"value":"115","label":" Ruby"},
    {"value":"116","label":" Seaborn"},
    {"value":"117","label":" Scipy"},
    {"value":"118","label":" Sed"},
    {"value":"119","label":" Tcl/Tk"},
    {"value":"120","label":" Unix"},
    {"value":"121","label":" VBScript"},
    {"value":"122","label":" WxPython"},
    {"value":"123","label":"Apex"},
    {"value":"124","label":" Arduino"},
    {"value":"125","label":" Assembly"},
    {"value":"126","label":" Awk"},
    {"value":"127","label":" C Standard Library"},
    {"value":"128","label":" Clojure"},
    {"value":"129","label":" COBOL"},
    {"value":"130","label":" Computer Programming"},
    {"value":"131","label":" C++"},
    {"value":"132","label":" C++ Standard Library"},
    {"value":"133","label":" C"},
    {"value":"134","label":" C#"},
    {"value":"135","label":" Dart"},
    {"value":"136","label":" D"},
    {"value":"137","label":" Data Structure & Algorithms"},
    {"value":"138","label":" Elixir"},
    {"value":"139","label":" Erlang"},
    {"value":"140","label":" Euphoria"},
    {"value":"141","label":" Fortran"},
    {"value":"142","label":" F#"},
    {"value":"143","label":" Go Programing"},
    {"value":"144","label":" Groovy"},
    {"value":"145","label":" Haskell"},
    {"value":"146","label":" Inter Process Communication"},
    {"value":"149","label":" Java Bean Utils"},
    {"value":"150","label":" JCL"},
    {"value":"151","label":" KDB+"},
    {"value":"152","label":" C by Examples"},
    {"value":"153","label":" LISP"},
    {"value":"154","label":" LOLCODE"},
    {"value":"155","label":" LOGO"},
    {"value":"156","label":" MATLAB"},
    {"value":"157","label":" Node.js"},
    {"value":"158","label":" Objective C"},
    {"value":"159","label":" OAuth2.0"},
    {"value":"160","label":" Pascal"},
    {"value":"161","label":" Parrot"},
    {"value":"162","label":" CGI with PERL"},
    {"value":"163","label":" R Programming"},
    {"value":"164","label":" Rexx Programming"},
    {"value":"165","label":" Scala"},
    {"value":"166","label":" Socket.io"},
    {"value":"167","label":" Swift"},
    {"value":"168","label":" VB.Net"},
    {"value":"169","label":" VBA"},
    {"value":"170","label":"Apache Ant"},
    {"value":"171","label":" Apache Common Collection"},
    {"value":"172","label":" Apache Common IO"},
    {"value":"173","label":" Apache POI (Powerpoint)"},
    {"value":"174","label":" Apache POI (Word)"},
    {"value":"175","label":" Apache POI"},
    {"value":"176","label":" AWT"},
    {"value":"177","label":" Apache Commons CLI"},
    {"value":"178","label":" Apache DB Utils"},
    {"value":"179","label":" Design Patterns"},
    {"value":"180","label":" EasyMock"},
    {"value":"181","label":" Eclipse"},
    {"value":"182","label":" EJB"},
    {"value":"183","label":" Gson"},
    {"value":"184","label":" Guava"},
    {"value":"185","label":" Guice"},
    {"value":"186","label":" GWT Google Charts"},
    {"value":"187","label":" GWT High Charts"},
    {"value":"188","label":" Hibernate"},
    {"value":"189","label":" iBATIS"},
    {"value":"190","label":" Intellij Idea"},
    {"value":"191","label":" Jackson"},
    {"value":"192","label":" JasperReports"},
    {"value":"213","label":" JavaFx"},
    {"value":"214","label":" JavaMail API"},
    {"value":"215","label":" Jbpm5"},
    {"value":"216","label":" JDB"},
    {"value":"217","label":" JDBC"},
    {"value":"218","label":" JFreeChart"},
    {"value":"219","label":" jMeter"},
    {"value":"220","label":" JSoup"},
    {"value":"221","label":" JOGL"},
    {"value":"222","label":" JPA"},
    {"value":"223","label":" JSON"},
    {"value":"224","label":" JSP"},
    {"value":"225","label":" JUnit"},
    {"value":"226","label":" log4j"},
    {"value":"227","label":" Lucene"},
    {"value":"228","label":" Maven"},
    {"value":"229","label":" PDFbox"},
    {"value":"230","label":" Servlets"},
    {"value":"231","label":" Spring"},
    {"value":"232","label":" Spring AOP"},
    {"value":"233","label":" Spring Batch"},
    {"value":"234","label":" Spring Boot Client"},
    {"value":"235","label":" Spring JDBC"},
    {"value":"236","label":" Spring MVC"},
    {"value":"237","label":" Spring Web Services"},
    {"value":"238","label":" Struts 2.x"},
    {"value":"239","label":" Swing"},
    {"value":"240","label":" TestNG"},
    {"value":"241","label":" Apache Tika"},
    {"value":"242","label":" Apache Xerces"},
    {"value":"243","label":" XStream"},
    {"value":"244","label":"Advanced Excel Charts"},
    {"value":"245","label":" Advanced Excel Functions"},
    {"value":"246","label":" Apache Flume"},
    {"value":"247","label":" Apache Kafka"},
    {"value":"248","label":" Apache Pig"},
    {"value":"249","label":" Apache Solr"},
    {"value":"250","label":" Apache Spark"},
    {"value":"251","label":" Apache Storm"},
    {"value":"252","label":" Apache Tajo"},
    {"value":"253","label":" Avro"},
    {"value":"254","label":" Big Data Analytics"},
    {"value":"255","label":" Cassandra"},
    {"value":"256","label":" Cognos"},
    {"value":"257","label":" CouchDB"},
    {"value":"258","label":" Dax Functions"},
    {"value":"259","label":" Data Modeling with DAX"},
    {"value":"260","label":" Excel Dashboard"},
    {"value":"261","label":" Excel Dax"},
    {"value":"262","label":" Excel Data Analysis"},
    {"value":"263","label":" Excel Pivot Tables"},
    {"value":"264","label":" Excel Macros"},
    {"value":"265","label":" Excel Power Pivot"},
    {"value":"266","label":" Excel Power View"},
    {"value":"267","label":" Google Charts"},
    {"value":"268","label":" Hadoop"},
    {"value":"269","label":" HBase"},
    {"value":"270","label":" HCatalog"},
    {"value":"271","label":" Highcharts"},
    {"value":"272","label":" Hive"},
    {"value":"273","label":" Apache Impala"},
    {"value":"274","label":" JFreeChart"},
    {"value":"275","label":" Mahout"},
    {"value":"276","label":" Map Reduce"},
    {"value":"277","label":" Pentaho"},
    {"value":"278","label":" Power BI"},
    {"value":"279","label":" QlikView"},
    {"value":"280","label":" R Programming"},
    {"value":"281","label":" SAS"},
    {"value":"282","label":" Spark SQL"},
    {"value":"283","label":" Sqoop"},
    {"value":"284","label":" Statistics"},
    {"value":"285","label":" Tableau"},
    {"value":"286","label":" Teradata"},
    {"value":"287","label":" Zookeeper"},
    {"value":"288","label":"CICS"},
    {"value":"289","label":" COBOL"},
    {"value":"290","label":" DB2"},
    {"value":"291","label":" IMS DB"},
    {"value":"292","label":" JCL"},
    {"value":"293","label":" VSAM"},
    {"value":"294","label":"Advanced Excel"},
    {"value":"295","label":" ASP.Net Core"},
    {"value":"296","label":" ASP.Net MVC"},
    {"value":"297","label":" ASP.Net WP"},
    {"value":"298","label":" ASP.Net"},
    {"value":"299","label":" Dot.Net Core"},
    {"value":"300","label":" Batch Script"},
    {"value":"302","label":" Entity Framework"},
    {"value":"303","label":" Excel Charts"},
    {"value":"304","label":" Excel"},
    {"value":"305","label":" F#"},
    {"value":"306","label":" LinQ"},
    {"value":"307","label":" MFC"},
    {"value":"308","label":" Microsoft Azure"},
    {"value":"309","label":" Microsoft CRM"},
    {"value":"310","label":" Microsoft Expression Web"},
    {"value":"311","label":" Microsoft Visio"},
    {"value":"312","label":" MicroStrategy"},
    {"value":"313","label":" Microsoft Access"},
    {"value":"314","label":" Microsoft Project"},
    {"value":"315","label":" MS SQl Server"},
    {"value":"316","label":" MVVM"},
    {"value":"317","label":" NHibernate"},
    {"value":"318","label":" Powerpoint"},
    {"value":"319","label":" Sharepoint"},
    {"value":"320","label":" Silverlight"},
    {"value":"321","label":" VB.Net"},
    {"value":"322","label":" VBA"},
    {"value":"323","label":" WCF"},
    {"value":"324","label":" Windows Server 12"},
    {"value":"325","label":" Windows 10 Development"},
    {"value":"326","label":" Windows 10"},
    {"value":"327","label":" Word"},
    {"value":"328","label":" WPF"},
    {"value":"329","label":" XAML"},
    {"value":"330","label":"ArangoDB"},
    {"value":"331","label":" Apache Presto"},
    {"value":"332","label":" CouchDB"},
    {"value":"333","label":" DB2"},
    {"value":"334","label":" DocumentDB SQL"},
    {"value":"335","label":" DocumentDB"},
    {"value":"336","label":" DynamoDB"},
    {"value":"337","label":" H2 Database"},
    {"value":"338","label":" HSQLDB"},
    {"value":"339","label":" IMS DB"},
    {"value":"340","label":" MariaDB"},
    {"value":"341","label":" Memcached"},
    {"value":"342","label":" MongoDB"},
    {"value":"343","label":" MySQL"},
    {"value":"344","label":" MySQLi"},
    {"value":"345","label":" Neo4J"},
    {"value":"346","label":" OBIEE"},
    {"value":"347","label":" OrientDB"},
    {"value":"348","label":" PL SQL"},
    {"value":"349","label":" PostgreSQL"},
    {"value":"350","label":" PouchDB"},
    {"value":"351","label":" Redis"},
    {"value":"352","label":" SQL"},
    {"value":"353","label":" SQLite"},
    {"value":"354","label":" T-SQL"},
    {"value":"355","label":"AIML"},
    {"value":"356","label":" DOM"},
    {"value":"357","label":" DTD"},
    {"value":"358","label":" ebXML"},
    {"value":"359","label":" Java XML"},
    {"value":"360","label":" WSDL"},
    {"value":"361","label":" XML-RPC"},
    {"value":"362","label":" XML"},
    {"value":"363","label":" XPath"},
    {"value":"364","label":" XQuery"},
    {"value":"365","label":" XSD"},
    {"value":"366","label":" XSLT"},
    {"value":"367","label":"Android"},
    {"value":"368","label":" Aurelia"},
    {"value":"369","label":" Cordova"},
    {"value":"370","label":" Ionic"},
    {"value":"371","label":" iOS"},
    {"value":"372","label":" iOS Development with Swift2"},
    {"value":"373","label":" jQuery Mobile"},
    {"value":"374","label":" Kotlin"},
    {"value":"375","label":" Meteor"},
    {"value":"376","label":" PhoneGap"},
    {"value":"377","label":" React Native"},
    {"value":"378","label":" SL4A"},
    {"value":"379","label":" Xamarin"},
    {"value":"380","label":"Ansible"},
    {"value":"381","label":" Bugzilla"},
    {"value":"382","label":" Chef"},
    {"value":"383","label":" Consul"},
    {"value":"384","label":" Docker"},
    {"value":"385","label":" Gerrit"},
    {"value":"386","label":" Git"},
    {"value":"387","label":" Gitlab"},
    {"value":"388","label":" Jira"},
    {"value":"389","label":" Kubernetes"},
    {"value":"390","label":" LogStash"},
    {"value":"391","label":" Makefile"},
    {"value":"392","label":" Mantis"},
    {"value":"393","label":" Puppet"},
    {"value":"394","label":" Saltstack"},
    {"value":"395","label":" Scrapy"},
    {"value":"396","label":" SVN"},
    {"value":"397","label":" UNIX"},
    {"value":"398","label":" Linux Admin"},
    {"value":"399","label":" Ubuntu"},
    {"value":"400","label":" Virtualization2.0"},
    {"value":"401","label":" VersionOne"},
    {"value":"402","label":"Agile Testing"},
    {"value":"403","label":" Apache Bench"},
    {"value":"404","label":" Balsamiq Mockups"},
    {"value":"405","label":" Bugzilla"},
    {"value":"406","label":" SEI CMMI"},
    {"value":"407","label":" Computer Security"},
    {"value":"408","label":" Concordion"},
    {"value":"409","label":" Continuous Integration"},
    {"value":"410","label":" Cucumber"},
    {"value":"411","label":" Database Testing"},
    {"value":"412","label":" Ethical Hacking"},
    {"value":"413","label":" ETL Testing"},
    {"value":"414","label":" Internet Security"},
    {"value":"415","label":" Jenkins"},
    {"value":"416","label":" Kali Linux"},
    {"value":"417","label":" Malware Removal"},
    {"value":"418","label":" Metasploit"},
    {"value":"419","label":" Mobile Security"},
    {"value":"420","label":" Mobile Testing"},
    {"value":"421","label":" Mockito"},
    {"value":"422","label":" Network Security"},
    {"value":"423","label":" Penetration Testing"},
    {"value":"424","label":" QC"},
    {"value":"425","label":" QTP"},
    {"value":"426","label":" QUnit"},
    {"value":"427","label":" QTest"},
    {"value":"428","label":" RSpec"},
    {"value":"429","label":" SAP Testing"},
    {"value":"430","label":" Security Testing"},
    {"value":"431","label":" Selenium"},
    {"value":"432","label":" Six Sigma"},
    {"value":"433","label":" Software Testing Dictionary"},
    {"value":"434","label":" Software Testing"},
    {"value":"435","label":" Software Quality Management"},
    {"value":"436","label":" SoapUI"},
    {"value":"437","label":" STLC"},
    {"value":"438","label":" System Analysis and Design"},
    {"value":"439","label":" TestLink"},
    {"value":"440","label":" TestLodge"},
    {"value":"441","label":" TesRail"},
    {"value":"442","label":" Unittest Framework"},
    {"value":"443","label":" Wireless Security"}
];
