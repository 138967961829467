export const Categories = [
  { id: '1', name: 'UX/UI' },
  { id: '2', name: 'Frontend Developer' },
  { id: '3', name: 'Backend Developer' },
  { id: '4', name: 'Fullstack Developer' },
  { id: '5', name: 'DevOps Engineer' },
  { id: '6', name: 'Software Quality Assurance' },
  { id: '7', name: 'Software Developer' },
  { id: '8', name: 'Mobile App (iOS)' },
  { id: '9', name: 'Mobile App (Android)' },
  { id: '10', name: 'Business Analyst ' },
  { id: '11', name: 'Product Manager' },
  { id: '12', name: 'Project Manager' },
  { id: '13', name: 'Hybrid Mobile Developer' },
  { id: '14', name: 'Database Admin' },
  { id: '15', name: 'Information Security' },
];
